// App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation, useNavigate } from 'react-router-dom';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import Procedure from './Procedure';
import AircraftInfo from './AircraftInfo'; 
import Settings from './Settings'; 
import AircraftSingleView from './AircraftSingleView';

const Navigation = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [value, setValue] = React.useState(location.pathname.slice(1) === 'settings' ? 'information' : location.pathname.slice(1));

  const handleChange = (event, newValue) => {
    setValue(newValue);
    navigate(`/${newValue}`);
  };

  return (
    <BottomNavigation
      showLabels
      value={value}
      onChange={handleChange}
      sx={{ 
        position: 'fixed', 
        bottom: 0, 
        width: '100%', 
        backgroundColor: '#0d47a1', 
      }}
    >
      <BottomNavigationAction
        label="Procedures"
        value="procedures"
        sx={{ 
          '.MuiBottomNavigationAction-label': { 
            fontSize: '1.2em', 
            fontWeight: 'bold', 
            padding: '0.5em',
            color: value === 'procedures' ? '#e8eaf6' : 'rgba(232, 234, 246, 0.6)', 
          } 
        }}
      />
      <BottomNavigationAction
        label="Aircraft"
        value="aircraft"
        sx={{ 
          '.MuiBottomNavigationAction-label': { 
            fontSize: '1.2em', 
            fontWeight: 'bold', 
            padding: '0.5em',
            color: value === 'aircraft' ? '#e8eaf6' : 'rgba(232, 234, 246, 0.6)', 
          } 
        }}
      />
      <BottomNavigationAction
        label="Information"
        value="information"
        sx={{ 
          '.MuiBottomNavigationAction-label': { 
            fontSize: '1.2em', 
            fontWeight: 'bold', 
            padding: '0.5em',
            color: value === 'information' ? '#e8eaf6' : 'rgba(232, 234, 246, 0.6)', 
          } 
        }}
      />
    </BottomNavigation>
  );
};

const App = () => {
  return (
    <Router>
      <div id="top">
        <Routes>
          <Route path="/" element={<Navigate to="/procedures" />} />
          <Route path="/procedures" element={<Procedure />} />
          <Route path="/aircraft/:id" element={<AircraftSingleView />} />
          <Route path="/aircraft" element={<AircraftInfo />} />
          <Route path="/information" element={<Settings />} />
        </Routes>
        <Navigation />
      </div>
    </Router>
  );
};

export default App;
