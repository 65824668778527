// settingsSlice.js
import { createSlice } from '@reduxjs/toolkit';

export const settingsSlice = createSlice({
  name: 'settings',
  initialState: {
  selectedLicense: 'PPL',
  selectedAircraftType: 'C172S',
  licenses: [],
  aircraftTypes: [],
},

  reducers: {
    setSelectedLicense: (state, action) => {
      state.selectedLicense = action.payload;
    },
    setSelectedAircraftType: (state, action) => {
      state.selectedAircraftType = action.payload;
    },
    setLicenses: (state, action) => {
      state.licenses = action.payload;
    },
    setAircraftTypes: (state, action) => {
      state.aircraftTypes = action.payload;
    },
  },
});

export const { setSelectedLicense, setSelectedAircraftType, setLicenses, setAircraftTypes } = settingsSlice.actions;
export default settingsSlice.reducer;
