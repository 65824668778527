// AircraftInfo.js

import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchAircraftInfo } from './aircraftSlice';
import { Select, MenuItem, Grid, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import AircraftListItem from './AircraftListItem';
import { Typography } from '@mui/material';

const AircraftInfo = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectedBase, setSelectedBase] = useState("HEF");
  const aircraftInfo = useSelector((state) => state.aircraft.aircraftData);

  useEffect(() => {
	if (aircraftInfo.length === 0) { 
	  dispatch(fetchAircraftInfo());
	}
  }, [dispatch, aircraftInfo]);

  const handleClick = (id) => {
	console.log("Clicked on: " + id);
	navigate(`/aircraft/${id}`);
  };

  return (
	<div>
	  <Box sx={{ position: 'sticky', top: 0, zIndex: 1, bgcolor: 'background.paper', p: 2 }}>
		<Typography variant="h6" component="div" sx={{ display: 'inline-block', mr: 1 }}>
		  Select Base:
		</Typography>
		<Select
		  value={selectedBase}
		  onChange={(event) => setSelectedBase(event.target.value)}
		>
		  <MenuItem value={"HEF"}>HEF</MenuItem>
		  <MenuItem value={"HWY"}>HWY</MenuItem>
		  <MenuItem value={"JYO"}>JYO</MenuItem>
		  <MenuItem value={"OKV"}>OKV</MenuItem>
		  <MenuItem value={"RMN"}>RMN</MenuItem>
		</Select>
	  </Box>
  
	  <Grid container spacing={3} sx={{ paddingBottom: '70px', px: '16px' }}>
		{aircraftInfo && aircraftInfo.filter((item) => item.base === selectedBase).map((aircraft) => (
		  <Grid item xs={12} sm={6} md={4}>
			<AircraftListItem key={aircraft.Id} aircraft={aircraft} onClick={() => handleClick(aircraft.Id)} />
		  </Grid>
		))}
	  </Grid>
	</div>
  );
};

export default AircraftInfo;

