// AircraftTypeSelectionView.js
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setSelectedAircraftType } from './settingsSlice';

const AircraftTypeSelectionView = () => {
  const dispatch = useDispatch();
  const selectedAircraftType = useSelector((state) => state.settings.selectedAircraftType);
  const aircraftTypes = useSelector((state) => state.settings.aircraftTypes);

  const handleChange = (e) => {
    dispatch(setSelectedAircraftType(e.target.value));
  };

  return (
    <div>
      <strong>Aircraft Type: </strong>
      <select value={selectedAircraftType} onChange={handleChange}>
        {aircraftTypes.map((aircraftType, index) => (
          <option key={index} value={aircraftType}>{aircraftType}</option>
        ))}
      </select>
    </div>
  );
};

export default AircraftTypeSelectionView;