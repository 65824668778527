// SettingsContext.js
import React, { createContext, useState } from 'react';

export const SettingsContext = createContext();

export const SettingsProvider = ({ children }) => {
  const [selectedLicense, setSelectedLicense] = useState(null);

  const resetFilters = () => {
    setSelectedLicense(null);
    // Reset other filters here
  };

  return (
    <SettingsContext.Provider value={{ selectedLicense, setSelectedLicense, resetFilters }}>
      {children}
    </SettingsContext.Provider>
  );
};
