// Procedure.js
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchProcedures } from './proceduresSlice';
import { setLicenses, setAircraftTypes } from './settingsSlice';
import ReactMarkdown from 'react-markdown';
import AircraftTypeSelectionView from './AircraftTypeSelectionView';
import LicenseSelectionView from './LicenseSelectionView';
import { Box, Typography, Divider } from '@mui/material';

const Procedure = () => {
  const dispatch = useDispatch();
  const procedures = useSelector((state) => state.procedures.procedures);
  const status = useSelector((state) => state.procedures.status);
  const error = useSelector((state) => state.procedures.error);
  const selectedLicense = useSelector((state) => state.settings.selectedLicense);
  const selectedAircraftType = useSelector((state) => state.settings.selectedAircraftType);

  useEffect(() => {
    dispatch(fetchProcedures());
  }, [dispatch]);

useEffect(() => {
    if (status === 'succeeded') {
      // Flatten the aircraft and license arrays
      const allAircraft = procedures.flatMap((procedure) => procedure.aircraft);
      const allLicenses = procedures.flatMap((procedure) => procedure.license);
  
      // Filter out null, undefined, or empty string values
      const filteredAircraft = allAircraft.filter(Boolean);
      const filteredLicenses = allLicenses.filter(Boolean);
  
      // Get unique aircraft and licenses
      const uniqueAircraft = Array.from(new Set(filteredAircraft));
      const uniqueLicenses = Array.from(new Set(filteredLicenses));
  
      dispatch(setLicenses(uniqueLicenses));
      dispatch(setAircraftTypes(uniqueAircraft));
    }
  }, [status, procedures, dispatch]);


  if (status === 'loading') {
    return <div>Loading...</div>;
  }

  if (status === 'failed') {
    return <div>Error: {error}</div>;
  }

  const filteredProcedures = procedures.filter(procedure => {
    return (selectedLicense === null || (procedure.license && procedure.license.includes(selectedLicense))) &&
           (selectedAircraftType === null || (procedure.aircraft && procedure.aircraft.includes(selectedAircraftType)));
  });

  // Generate the TOC based on procedure names
  const tableOfContents = filteredProcedures.map((procedure, index) => ({
    id: `procedure-${index}`,
    title: procedure.name
  }));

  return (
      <Box sx={{ p: 3 }}>
        <Typography variant="h4" mb={2}>AvAd Procedures</Typography>
  
        <LicenseSelectionView />
        <AircraftTypeSelectionView />
  
        <Typography variant="h5" mt={3} mb={2}>Table of Contents</Typography>
        <Box mb={3} sx={{ borderBottom: '1px solid grey', pb: 2 }}>
          <ul>
            {tableOfContents.map(({ id, title }) => (
              <li key={id}>
                <a href={`#${id}`}>{title}</a>
              </li>
            ))}
          </ul>
        </Box>
  
        {filteredProcedures.map((procedure, index) => (
          <Box key={index} mb={4}>
            <Typography variant="h4" id={`procedure-${index}`} mb={1}>{procedure.name}</Typography>
            <a href="#top">Return to top</a>
  
            {procedure.info.memoryItemCount > 0 && (
              <Box mt={3}>
                <Typography variant="h5" mb={1}>Memory Items</Typography>
                {procedure.info.memoryItems.slice(0, procedure.info.memoryItemCount).map((item) => (
                  <Box key={item.id} mt={1}>
                    {item.description}: {item.speed}
                  </Box>
                ))}
              </Box>
            )}
  
            <Box mt={3}>
              <Typography variant="h5" mb={1}>Procedure Steps</Typography>
              {procedure.steps.map((step, index) => (
                <Box key={index} mt={1}>
                  <ReactMarkdown>{step}</ReactMarkdown>
                </Box>
              ))}
            </Box>
  
            {index < filteredProcedures.length - 1 && (
              <Box my={3}>
                <Divider />
              </Box>
            )}
          </Box>
        ))}
      </Box>
    );
  }
  
  export default Procedure;