// Settings.js
import React, { useState, useEffect } from 'react';
import { Button, Typography, Box, Link } from '@mui/material';
import documentsData from './documents.json'; 

const Settings = () => {
  const [documents, setDocuments] = useState({});


 /* const loadDocuments = async () => {
	try {
	  const response = await axios.get('/pilot-procedures/documents.json');
	  return response.data;
	} catch (error) {
	  console.error('Error while loading documents:', error);
	  return {};
	}
  };*/

  useEffect(() => {
	setDocuments(documentsData);
  }, []);


  return (
	<Box sx={{ p: 3 }}>
	  <Typography variant="h4" mb={2}>Reference Information</Typography>

	  <Box my={2}>
		<Typography variant="h6">Documents</Typography>
		{Object.keys(documents).sort().map((key) => {
		  const document = documents[key];
		  return (
			<Box key={key} mb={2}>
			  <Typography variant="subtitle1">{document.title}</Typography>
			  <Typography variant="body2">Date Updated: {document.date}</Typography>
			</Box>
		  );
		})}
	  </Box>

	  <Box my={2}>
		<Typography variant="h6">Disclaimer</Typography>
		<Typography variant="body1" mt={1}>
		  The information in this product is for reference only and cannot be used to replace formal training or be used for flight. Refer to the Aircraft's Flight Manual and Pilot's Operating Handbook for the most accurate information.
		</Typography>
	  </Box>

	  <Link href="itms-apps://itunes.apple.com/app/6449366143" underline="none">
		<Button variant="outlined" color="primary" sx={{ mt: 3 }}>
		  Download the AvAd Companion App for iOS
		</Button>
	  </Link>

	  <Box sx={{ mt: 3, color: 'grey.500' }}>
		<Typography variant="body2">
		  ©️ 2023 Aviation Adventures and Ryan Dlugash
		</Typography>
		<Typography variant="body2">
		  Made in Washington, DC
		</Typography>
	  </Box>

	  {/* Your Privacy and About pages navigation here. Depends on your routing method. */}
	</Box>
  );
};

export default Settings;
