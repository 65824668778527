// store.js

import { configureStore } from '@reduxjs/toolkit';
import proceduresReducer from './proceduresSlice';
import settingsReducer from './settingsSlice'; 
import aircraftReducer from './aircraftSlice';

export default configureStore({
  reducer: {
    procedures: proceduresReducer,
    settings: settingsReducer, 
    aircraft: aircraftReducer,
  },
});
