// AircraftListItem.js

import React from 'react';
import { Card, CardContent, Typography, Button, Box } from '@mui/material';

const AircraftListItem = ({ aircraft, onClick }) => {
  return (
	<Card>
	  <Button onClick={onClick} fullWidth>
		<CardContent>
		  <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
			<Typography variant="h5" align="center" noWrap>{aircraft.tailnum} - {aircraft.typeac}</Typography>
			<Typography variant="body1" align="center" noWrap>Base: {aircraft.base}</Typography>
			<Typography variant="body1" align="center" noWrap>Callsign: {aircraft.callsign}</Typography>
		  </Box>
		</CardContent>
	  </Button>
	</Card>
  );
};

export default AircraftListItem;
