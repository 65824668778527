// AircraftSingleView.js

import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams, Link as RouterLink } from 'react-router-dom';
import { Card, CardContent, Typography, Divider, Button, Link } from '@mui/material';

const AircraftSingleView = () => {
  const { id } = useParams();
  const [aircraft, setAircraft] = useState(null);
  const aircraftData = useSelector((state) => state.aircraft.aircraftData);

  useEffect(() => {
	const aircraft = aircraftData.find((item) => item.Id === id);
	setAircraft(aircraft);
  }, [id, aircraftData]);

  return (
	<div>
	  {aircraft && (
		<Card>
		  <CardContent>
			<Typography variant="h5">{aircraft.tailnum} - {aircraft.typeac}</Typography>
			<Typography variant="body1">Base: {aircraft.base}</Typography>
			<Typography variant="body1">Callsign: {aircraft.callsign}</Typography>
			<Typography variant="body1">
			  <a href={aircraft.website} target="_blank" rel="noreferrer">View on AviationAdventures.com</a>
			</Typography>
			<Typography variant="body1">
			  <a href={aircraft.tracking} target="_blank" rel="noreferrer">Track on FlightAware</a>
			</Typography>
			<Divider sx={{ my: 2 }} />
			<Typography variant="body1">Surveillance: {aircraft.filinginfo.surveillance}</Typography>
			<Typography variant="body1">Mode S Hex: {aircraft.filinginfo.modeshex}</Typography>
			<Typography variant="body1">Equipment: {aircraft.filinginfo.equipment}</Typography>
			<Typography variant="body1">RNAV/PBN: {aircraft.filinginfo.rnavpbn}</Typography>
			<Typography variant="body1">
			  <Link href="https://www.faa.gov/air_traffic/publications/atpubs/aim_html/appendix_4.html" target="_blank" rel="noreferrer">
				See AIM appendix 4 paragraph 4.d.(f) for guidance on using these codes.
			  </Link>
			</Typography>
		  </CardContent>
<Button component={RouterLink} to="/aircraft" variant="contained" color="primary" sx={{ mt: 2, ml: 1 }}>Back to List</Button>
		</Card>
	  )}
	</div>
  );
};

export default AircraftSingleView;
