// LicenseSelectionView.js
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setSelectedLicense } from './settingsSlice';

const LicenseSelectionView = () => {
  const dispatch = useDispatch();
  const selectedLicense = useSelector((state) => state.settings.selectedLicense);
  const licenses = useSelector((state) => state.settings.licenses);

  const handleChange = (e) => {
    dispatch(setSelectedLicense(e.target.value));
  };

  return (
    <div>
      <strong>Certificate: </strong>
      <select value={selectedLicense} onChange={handleChange}>
        {licenses.map((license, index) => (
          <option key={index} value={license}>{license}</option>
        ))}
      </select>
    </div>
  );
};

export default LicenseSelectionView;
