//proceduresSlice.js

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import proceduresData from './procedures.json';

// Fetching procedures data from the server
export const fetchProcedures = createAsyncThunk('procedures/fetchProcedures', async () => {
  return proceduresData.procedures;
});

const proceduresSlice = createSlice({
  name: 'procedures',
  initialState: { procedures: [], status: 'idle', error: null },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchProcedures.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchProcedures.fulfilled, (state, action) => {
        state.status = 'succeeded';
        // Replace existing procedures with the fetched procedures
        state.procedures = action.payload;
      })
      .addCase(fetchProcedures.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export default proceduresSlice.reducer;
