// aircraftSlice.js
//hi

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import aircraftInfo from './aircraftInfo.json';

export const fetchAircraftInfo = createAsyncThunk('aircraft/fetchAircraftInfo', async () => {
  return aircraftInfo;
});

const aircraftSlice = createSlice({
  name: 'aircraft',
  initialState: { aircraftData: [], status: 'idle', error: null },
  reducers: {},
  extraReducers: (builder) => {
	builder
	  .addCase(fetchAircraftInfo.pending, (state) => {
		state.status = 'loading';
	  })
	  .addCase(fetchAircraftInfo.fulfilled, (state, action) => {
		state.status = 'succeeded';
		state.aircraftData = state.aircraftData.concat(action.payload);
	  })
	  .addCase(fetchAircraftInfo.rejected, (state, action) => {
		state.status = 'failed';
		state.error = action.error.message;
	  });
  },
});

export default aircraftSlice.reducer;
